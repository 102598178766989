.chat {
  display: grid;
  grid-template-columns: 1fr 2fr;
  box-shadow: $box-shadow;
  border-radius: 20px;
  background-color: #f4f4f4;

  &__main {
    border-radius: 0 20px 20px 0;
    border-left: 1px solid #ededed;
    position: relative;
    min-height: 500px;

    &__top {
      padding: 15px 20px;
      font-weight: 600;
      font-size: 18px;
      //   border-bottom: 1px solid #e9e9e9;
      background-color: $primary-color;
      color: #fff;
      border-radius: 0 20px 0 0;
    }
    &__input {
      position: absolute;
      bottom: 20px;
      width: 100%;
      padding: 0 20px;

      & input {
        border-radius: 50px;
        height: 50px;
        width: 100%;
        border: none;
        padding: 0 20px;
        padding-right: 40px;
        box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
          rgba(17, 17, 26, 0.05) 0px 8px 32px;

        &:focus {
          border: none;
          outline: none;
        }
      }

      & i {
        position: absolute;
        right: 40px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 18px;
      }
    }

    &__message {
      height: 500px;
      display: flex;
      flex-direction: column-reverse;
      gap: 15px;
      padding: 40px 20px;
      padding-bottom: 100px;
      border-radius: 0 0 20px 0;
      overflow-y: auto;

      &__item {
        display: flex;
        align-items: flex-end;
        flex-direction: column;

        &.alt {
          align-items: flex-start;
        }

        &__text {
          //   color: #fff;
          background-color: #cceaccd7;
          padding: 10px 20px;
          max-width: 50%;
          border-radius: 10px;
          position: relative;
          color: #494949;

          &.alt {
            background-color: #fff;
          }

          &__ind {
            position: absolute;
            width: 0;
            height: 0;
            display: inline-block;
            top: 0;
            right: -8px;
            // transform: rotate(20deg);
            border: 10px solid;
            border-color: #cceaccd7 transparent transparent transparent;

            &.alt {
              left: -8px;
              border-color: #fff transparent transparent transparent;
            }
          }
        }

        &__date {
          font-size: 11px;
          color: #a5a5a5;
          padding: 2px 3px;
        }
      }
    }
  }
}
