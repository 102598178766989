.topbar {
  width: calc(100vw - 225px);
  height: 75px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  gap: 20px;
  background: #fff;
  -webkit-box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.1);
  color: #b1a7a7;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    padding: 0 20px;
  }

  &__modal {
    background-color: #fff;
    box-shadow: 0 0 45px 0 rgba(131, 23, 254, 0.06);
    border-radius: 10px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 227px;
    position: absolute;
    top: 75px;
    right: 0;

    &__img {
      & img {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        background-color: #f8f8f8;
        margin-bottom: 20px;
        object-fit: cover;
      }
    }

    & h3 {
      font-size: 14px;
      font-weight: 700;
    }

    &__list {
      padding: 0;
      margin: 0;
      width: 100%;
      margin-top: 10px;

      & li {
        display: flex;
        gap: 15px;
        padding: 0 30px;
        height: 50px;
        align-items: center;
        color: #8a8a8a;
        font-weight: 300;
        transition: 0.3s;
        cursor: pointer;

        &:hover {
          background-color: #f8f9fa;
        }

        &.last {
          border-top: 1px solid #f5f4f4;
          border-bottom-left-radius: 10px;
        }
      }
    }
  }

  &__left {
    @media screen and (max-width: 650px) {
      display: none;
    }

    &__input {
      position: relative;

      & input {
        border: 1px solid #eaeaea;
        width: 220px;
        padding-left: 40px;
        border-radius: 10px;
        height: 40px;

        &::placeholder {
          color: #b1a7a7;
        }

        &:focus {
          outline: none;
          border: 1px solid $secondary-color;
        }
      }

      & i {
        position: absolute;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &__info {
    display: flex;
    align-items: center;
    gap: 25px;

    &__noti {
      position: relative;
      cursor: pointer;
      & i {
        font-size: 18px;
      }
      & span {
        position: absolute;
        right: -4px;
        top: -7px;
        display: inline-block;
        height: 8px;
        width: 8px;
        background-color: #263992;
        border-radius: 50%;
      }
    }

    &__user {
      display: flex;
      gap: 15px;
      align-items: center;
      cursor: pointer;

      &__img {
        & img {
          width: 40px;
          height: 40px;
          border-radius: 10px;
          background-color: #f8f8f8;
          object-fit: cover;
        }
      }
    }
  }
}
