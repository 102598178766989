.sidebar {
  width: 225px;
  background-color: $primary-color;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: scroll;

  @media screen and (max-width: 1000px) {
    display: none;

    &.draw {
      display: flex;
    }
  }

  &__top {
    border-bottom: 1px solid #2e435f;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    padding: 20px;

    &__logo {
      width: 120px;
    }
  }

  &__list {
    padding: 0;
    margin: 0;
    margin-top: 20px;
    margin-right: 10px;

    &__item {
      padding: 0 22px;
      cursor: pointer;
      transition: 0.3s;
      letter-spacing: 0.5px;
      display: flex;
      align-items: center;
      gap: 15px;
      height: 40px;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      color: #b5b4b4;
      font-weight: 200;
      margin-bottom: 10px;
      transition: 0.3s;

      & i {
        color: #fff;
      }

      &:hover {
        background-color: #00224b;
        color: #fff;
      }
      &.active {
        background-color: #00224b;
        color: #fff;
      }
    }

    &__itemCon {
      transition: 0.3s;

      &:hover .sidebar__list__item {
        background-color: #00224b;
        color: #fff;
      }
    }

    &__dropdown {
      top: -8px;
      position: relative;
      display: flex;
      flex-direction: column;
      padding-left: 30px;
      border-left: 2px solid rgba(76, 76, 84, 0.709);
      margin-left: 29px;
      transition: 0.3s;

      & a {
        color: #b5b4b4;
        font-weight: 200;

        &:hover {
          color: #fff;
        }
      }

      &__item {
        padding: 5px 0;
        display: inline-block;

        &.active {
          color: #fff;
        }
      }
    }
  }

  &__btn {
    width: 150px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
}
