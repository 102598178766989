.notificationModal {
  &__modal {
    background-color: #fff;
    box-shadow: 0 0 45px 0 rgba(131, 23, 254, 0.06);
    border-radius: 10px;
    width: 300px;
    position: absolute;
    top: 75px;
    right: 210px;

    @media screen and (max-width: 550px) {
      left: 0;
    }

    &__top {
      padding: 20px 20px;
      background-color: $primary-color;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #fff;

      & button {
        border: none;
        border-radius: 5px;
        background-color: #fff;
        height: 30px;
        font-size: 13px;
      }
    }

    &__list {
      padding: 0;
      &__item {
        display: flex;
        align-items: center;
        gap: 20px;
        border-top: 1px solid #f5f2f2;
        padding: 10px 20px;

        & img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background-color: #e5e4e4;
          object-fit: cover;
        }

        & h3 {
          color: #616161;
        }

        & p {
          font-size: 12px;
        }

        & span {
          font-size: 11px;
          font-weight: 300;
        }
      }
    }
  }
}
