.authLayout {
  display: flex;

  &__left {
    width: 479px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    justify-content: center;
    align-items: center;
    padding: 30px;
    // background: $primary-color;
    background: url(../../../images/auth2.jpg);
    background-position: center;
    // background: url(../../../images/authbg.jpg);
    // background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: inset 0 0 0 1000px #022d62c6;

    height: 100vh;
    color: #eeeeee;

    & img {
      width: 200px;
      //   background-color: #fff;
      border-radius: 5px;
    }

    & p {
      text-align: center;
      //   max-width: 380px;
      font-size: 15px;
      letter-spacing: 1px;
    }
  }

  &__right {
    width: calc(100% - 479px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 1000px) {
  .authLayout {
    flex-direction: column;

    &__left {
      height: auto;
      width: 100vw;
      padding: 50px 20px;
    }

    &__right {
      margin: 50px 0;
      width: 100%;
    }
  }
}
