.login {
  background-color: #fff;
  -webkit-box-shadow: 0 18px 37.7px 15.3px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0 18px 37.7px 15.3px rgba(0, 0, 0, 0.07);
  box-shadow: 0 18px 37.7px 15.3px rgba(0, 0, 0, 0.07);
  padding: 4%;
  width: 50%;
  border-radius: 10px;

  @media screen and (max-width: 1100px) {
    width: 80%;
  }

  &__title {
    font-size: 20px;
    margin-bottom: 10px;
    text-align: center;
  }

  &__subtitle {
    margin-bottom: 20px;
    text-align: center;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__input {
      position: relative;

      & input {
        width: 100%;
        border: none;
        border-bottom: 1px solid #e6e6e6;
        padding: 10px 0;
        transition: 0.3s;

        &::placeholder {
          color: #c4c4c4;
        }

        &:focus {
          outline: none;
          border-bottom: 1px solid $secondary-color;
        }
      }

      & i {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        color: $secondary-color;
      }
    }

    &__forgot {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;
    }
  }

  &__dont {
    display: flex;
    justify-content: center;
    margin-top: 50px;

    & a {
      margin-left: 10px;
    }
  }
}
