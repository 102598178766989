.message {
  display: flex;
  justify-content: center;
  &__main {
    box-shadow: $box-shadow;
    background-color: #fff;
    padding: 30px;
    max-width: 900px;
    width: 100%;

    &__form {
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      margin-bottom: 30px;

      &__item {
        width: 100%;
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 15px;

      &__item {
        border-bottom: 1px solid #e2e2e2;
        padding: 10px 0;

        & h4 {
          margin-bottom: 10px;
        }
      }
    }
  }
}
