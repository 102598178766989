.profile {
  padding-bottom: 30px;

  &__main {
    background-color: #fff;
    box-shadow: $box-shadow;
    padding-bottom: 30px;

    &__title {
      padding: 30px;
      font-size: 17px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #dfdfdf;

      & i {
        cursor: pointer;
      }
      &.active {
        & i {
          color: $success-color;
        }
      }
    }

    &__con {
      display: flex;
      align-items: flex-start;
      gap: 20px;

      @media screen and (max-width: 700px) {
        flex-direction: column;
      }
    }

    &__img {
      width: 180px;
      margin: 0 30px;
      margin-top: 30px;
      display: flex;
      // align-items: center;
      flex-direction: column;
      gap: 20px;
      margin-right: 0;
      // justify-content: center;
      @media screen and (max-width: 700px) {
        width: 100%;
      }

      & input {
        width: 100%;
      }

      & img {
        width: 120px;
        height: 110px;
        border-radius: 10px;
        object-fit: cover;
        background-color: #f8f8f8;
      }
    }

    &__info {
      width: calc(100% - 200px);

      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 30px;
      column-gap: 30px;
      padding-bottom: 0;
      padding-left: 0;

      @media screen and (max-width: 900px) {
        grid-template-columns: 1fr;
      }
      @media screen and (max-width: 700px) {
        width: 100%;
        padding-left: 30px;
      }

      &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 60px;
        border-top: 1px solid #dee2e6;
        gap: 20px;
        flex-wrap: wrap;
        padding: 10px 0;

        & b {
          font-size: 13px;
          color: #212529;
          width: 100px;
        }
      }
    }

    &__btn {
      padding: 20px 30px;
      padding-bottom: 0;
      display: flex;
      justify-content: flex-end;
    }
  }
}
