.service {
  &__main {
    background-color: #fff;
    box-shadow: $box-shadow;
    padding: 50px 30px;

    & h3 {
      font-size: 25px;
      margin-bottom: 30px;
    }

    &__list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 30px;

      &__item {
        & p {
          font-size: 15px;
        }

        & h5 {
          font-size: 15px;
          margin-bottom: 10px;
        }

        & h6 {
          font-size: 13px;
          color: gray;
          margin-top: 2px;
        }

        & i {
          font-size: 16px;
          display: inline-block;
          color: $primary-color;
          cursor: pointer;
        }

        &__action {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 10px;
        }
      }
    }
  }
}
