$primary-color: #022d62;
$secondary-color: #034c65;
$tetiary-color: #fa5230;

$text-color: #676c71;
$textAlt-color: #000;

$success-color: #22b548;
$error-color: #a92424;

$box-shadow: -4px 7px 8px 0 rgba(15, 32, 60, 0.1);
