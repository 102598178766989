.addBlog {
  display: flex;
  justify-content: center;
  &__main {
    background-color: #fff;
    box-shadow: $box-shadow;
    width: 100%;
    max-width: 900px;

    & h1 {
      padding: 30px;
      font-size: 17px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #dfdfdf;

      & i {
        color: $primary-color;
        font-weight: bold;
        font-size: 20px;
      }
    }

    &__form {
      padding: 30px;

      &__item {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        & input {
          width: 100%;
        }
      }

      &__btn {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 30px;
      }
    }
  }
}
