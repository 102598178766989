:root {
  font-size: 62.5%;
}

@import "abstracts/variable";

@import "layouts/authLayout";
@import "layouts/dashboardLayout";

@import "components/sidebar";
@import "components/drawerDB";
@import "components/topbar";
@import "components/tableMenu";
@import "components/notificationModal";
@import "components/conversation";

@import "pages/notFound";
@import "pages/login";
@import "pages/settings";
@import "pages/profile";
@import "pages/changePassword";
@import "pages/dashboard";
@import "pages/staff";
@import "pages/careerApp";
@import "pages/careerItem";
@import "pages/imagesHome";
@import "pages/prices";
@import "pages/addBlog";
@import "pages/blog";
@import "pages/chat";
@import "pages/message";
@import "pages/portfolio";
@import "pages/testimonials";
@import "pages/service";

*,
::before,
::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-size: 14px;
  font-family: "Overpass", sans-serif;
  color: $text-color;
}

a {
  text-decoration: none;
  color: $secondary-color;
}

img {
  max-width: 100%;
  height: auto;
}

li {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $textAlt-color;
}

.btnMain {
  width: 180px;
  height: 50px;
  background-color: $tetiary-color;
  color: #fff;
  border: none;
  border-radius: 30px;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.btnMainAlt {
  width: 180px;
  height: 50px;
  background-color: $primary-color;
  color: #fff;
  border: none;
  border-radius: 30px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.loadingCon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.suc {
  color: $success-color;
}
.suc {
  color: $success-color;
}
.suc {
  color: $success-color;
}

.fail {
  color: $error-color;
}

/////////// MODAL STYLES ////////////

.modal-overlayUser {
  background: transparent;
  z-index: 50;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  height: 100%;
}

.modal-overlayCenter {
  background: rgba(0, 0, 0, 0.5);
  z-index: 50;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.ReactModal__Content {
  &:focus-visible {
    outline: none !important;
  }
}

/////////// PAGINATE ////////////

.paginationBttns {
  padding: 0;
  /* height: 20px; */
  list-style: none;
  display: flex;
  // align-self: flex-end;
  justify-content: center;
  flex-wrap: wrap;
}

.paginationBttns a {
  height: 33px;
  width: 35px;
  margin: 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 14px;
  background-color: rgb(226, 226, 226);
  border-radius: 6px;
  cursor: pointer;
  transition: 0.3s;
}

.paginationBttns a:hover {
  color: white !important;
  background-color: $primary-color !important;
}

.paginationActive a {
  color: white !important;
  background-color: $primary-color !important;
}

.paginationDisabled a {
  color: #c3c3c3;
  background-color: transparent;

  &:hover {
    color: #c3c3c3;
    background-color: transparent !important;
  }
}
.pignateCon {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
