.blog {
  &__top {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
  }

  &__main {
    background-color: #fff;
    box-shadow: $box-shadow;
    padding: 30px;

    &__list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      margin-top: 30px;
      gap: 30px;
      margin-bottom: 20px;

      &__item {
        & img {
          height: 300px;
          width: 100%;
          object-fit: cover;
          margin-bottom: 10px;
        }

        & h4 {
          margin-bottom: 10px;
          margin-top: 20px;
        }

        &__date {
          display: flex;
          gap: 20px;
          justify-content: space-between;
          margin-bottom: 10px;
          align-items: center;

          & p {
            background-color: #fa523018;
            padding: 5px 15px;
            border-radius: 20px;
            color: $tetiary-color;
          }
        }

        &__sum {
          color: $text-color;
        }

        &__action {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 20px;

          & i {
            font-size: 18px;
            color: $primary-color;
            cursor: pointer;

            &.del {
              color: $error-color;
            }
          }
        }
      }
    }
  }
}
