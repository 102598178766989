.dashboard {
  &__top {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5%;

    @media screen and (max-width: 600px) {
      grid-template-columns: 1fr;
    }

    &__card {
      box-shadow: $box-shadow;
      border-radius: 5px;
      padding: 30px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;

      & i {
        font-size: 35px;
      }

      & p {
        color: #233650;
        font-size: 24px;
        font-weight: 600;
        padding: 5px 0;
      }

      & span {
        font-size: 13px;
      }

      & h4 {
        font-size: 14px;
        font-weight: 400;
      }

      &__type {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        font-size: 25px;
        gap: 10px;

        & span {
          font-size: 15px;
          font-weight: 500;
        }
      }
    }
  }

  &__users {
    box-shadow: $box-shadow;
    background-color: #fff;
    border-radius: 5px;
    // padding: 30px;
    margin-top: 30px;
    min-height: 600px;

    &__top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // border-bottom: 1px solid #dfdfdf;
      padding: 30px;
    }

    & h3 {
      font-size: 17px;
    }

    &__table {
      overflow: auto;
      padding: 30px;
      padding-top: 0px;

      &__body {
        display: flex;
        flex-direction: column;
        min-width: 900px;
      }

      &__row {
        padding: 15px 0;
        display: flex;
        justify-content: space-between;
        gap: 10px;

        border-top: 1px solid rgb(219, 219, 219);
        // &.head {
        //   border: none;
        // }
        //   &:not(:last-child) {
        //     border-bottom: 1px solid rgb(184, 184, 184);
        //   }

        &__head {
          font-weight: 600;
          font-size: 12px;
          text-transform: uppercase;
          color: $textAlt-color;
        }

        &__data {
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;

          & h4 {
            font-weight: 700;
          }

          &__btn {
            width: 100px;
            height: 30px;
            border: none;
            background-color: $primary-color;
            color: #fff;
            cursor: pointer;
            font-size: 13px;
            border-radius: 2px;
          }
        }
      }
    }
  }
}
