.changePassword {
  padding-bottom: 30px;

  &__main {
    background-color: #fff;
    box-shadow: $box-shadow;
    width: 500px;
    @media screen and (max-width: 550px) {
      width: 100%;
    }

    & h2 {
      padding: 30px;
      font-size: 17px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #dfdfdf;
    }

    &__form {
      padding: 30px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      //   width: 500px;
    }
  }
}
