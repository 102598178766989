.careerItem {
  padding-bottom: 30px;
  &__main {
    box-shadow: $box-shadow;
    background-color: #fff;
    border-radius: 10px;
    padding: 30px;
    max-width: 900px;
    margin: 0 auto;

    &__btn {
      display: flex;
      justify-content: center;
      margin-top: 30px;
    }

    &__total {
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 5px;

      &__title {
        font-size: 30px;
        margin-top: 50px;
        margin-bottom: 10px;
      }

      &__part {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 30px;
        padding: 13px 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        font-weight: 500;

        // & a {
        //   color: #337ab7;
        // }

        &.shipping {
          align-items: flex-start;
          background-color: rgba(0, 0, 0, 0.025);
        }

        &__inputs {
          &__item {
            margin-bottom: 10px;
          }

          &__info {
            margin-bottom: 20px;
          }

          &__change {
            & select {
              margin-top: 5px;
              width: 180px;
              height: 30px;
              border: 1px solid #bcbcbc;
              padding: 0 10px;
              border-radius: 4px;
            }
          }
        }
      }
    }
  }
}
