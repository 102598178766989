.dashboardLayout {
  &__main {
    padding-top: 100px;
    padding-left: 265px;
    padding-right: 40px;
    background-color: #ededed;
    min-height: 100vh;
    padding-bottom: 30px;
    // position: relative;

    @media screen and (max-width: 1000px) {
      padding-left: 40px;
    }

    @media screen and (max-width: 600px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
