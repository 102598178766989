.settings {
  &__header {
    background-color: #fff;
    box-shadow: $box-shadow;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid $primary-color;
    margin-bottom: 30px;

    &__list {
      display: flex;
      padding: 0;
      margin: 0;

      & li {
        font-size: 15px;
        padding: 10px 30px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        transition: 0.3s;
        cursor: pointer;

        &:hover {
          background-color: $primary-color;
          color: #fff;
        }
        &.active {
          background-color: $primary-color;
          color: #fff;
        }
      }
    }
  }
}
