.tableMenu {
  & i {
    color: $primary-color;
  }

  &__modal {
    width: 350px;
    background-color: #fff;
    padding: 20px;
    border-radius: 2px;

    & p {
      //   font-weight: 600;
      font-size: 15px;
    }

    &__btn {
      display: flex;
      justify-content: flex-end;
      gap: 20px;
      margin-top: 30px;
      flex-wrap: wrap;

      &__item {
        cursor: pointer;
        height: 35px;
        width: 100px;
        border: none;
        background-color: rgb(231, 231, 231);

        &.delete {
          background-color: rgb(190, 1, 1);
          color: #fff;
        }
      }
    }
  }
}
