.careerApp {
  &__header {
    display: flex;
    flex-direction: column;
    // gap: 50px;
    min-height: 300px;
    justify-content: center;
    align-items: center;
    padding: 30px;
    // background: $primary-color;
    background: url(../../../images/auth2.jpg);
    background-position: center center;
    // background: url(../../../images/authbg.jpg);
    // background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: inset 0 0 0 1000px #022d62c6;
    color: #eeeeee;

    & img {
      width: 140px;
      margin-bottom: 30px;
    }

    & h1 {
      color: #eeeeee;
      margin-bottom: 10px;
      font-size: 50px;
    }

    & p {
      max-width: 1000px;
      text-align: center;
      font-size: 15px;
    }
  }

  &__main {
    padding: 50px 15%;

    & h3 {
      font-size: 16px;
      margin-top: 30px;
      margin-bottom: 10px;
    }

    &__btn {
      margin-top: 40px;
      display: flex;
      justify-content: center;
    }

    & input {
      margin-bottom: 15px;
    }

    &__fileLabel {
      height: 80px;
      width: 100%;
      max-width: 400px;
      background-color: #fa52301c;
      border-radius: 10px;
      border: 1px dashed #fa5230aa;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      text-align: center;
      flex-direction: column;
      gap: 5px;
      color: #fa5230;

      & span {
        font-size: 12px;
      }
    }

    &__price {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 30px;

      & p {
        color: $primary-color;
      }
    }
  }
}
