.portfolio {
  &__main {
    background-color: #fff;
    box-shadow: $box-shadow;
    padding: 30px;

    &__product {
      padding-bottom: 80px;

      & h2 {
        font-size: 30px;
        margin-top: 20px;
        margin-bottom: 30px;
      }

      & p {
        font-size: 18px;
        margin-bottom: 30px;
      }

      &__list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 30px;

        &.alt {
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        }
        &__item {
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          & img {
            width: 100%;
            /* max-height: 400px; */
            /* object-fit: cover; */
            padding-bottom: 10px;
          }

          & h3 {
            font-size: 16px;
            border-bottom: 1px solid #ef3139;
            padding-bottom: 15px;
          }

          &__action {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;

            & i {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .portfolio__main__product__list {
    grid-template-columns: 1fr 1fr;
  }
  .portfolio__main__product__list.alt {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 750px) {
  .portfolio__main__product__list {
    grid-template-columns: 1fr;
  }
  .portfolio__main__product__list.alt {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 500px) {
  .portfolio__main__product__list.alt {
    grid-template-columns: 1fr 1fr;
  }
}
