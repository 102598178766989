.staff {
  &__btn {
    display: flex;
    justify-content: flex-end;

    & i {
      font-weight: bold;
    }
  }
  &__main {
    box-shadow: $box-shadow;
    background-color: #fff;
    border-radius: 5px;
    margin-top: 20px;
    min-height: 600px;
  }
}
