.conversation {
  padding: 20px;
  background-color: #fff;
  padding-bottom: 80px;
  position: relative;
  border-radius: 20px 0 0 20px;
  &__search {
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 30px;
    margin-bottom: 20px;
    & input {
      border-radius: 50px;
      height: 50px;
      width: 100%;
      border: 1px solid #eaeaea;
      padding: 0 20px;
    }
  }

  &__list {
    &__item {
      padding: 15px 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 10px;
      cursor: pointer;

      &:not(:last-child) {
        border-bottom: 1px solid #eaeaeaa9;
      }

      &.active {
        background-color: #022d620b;
        // color: #fff;
      }

      &__left {
        display: flex;
        align-items: center;
        gap: 10px;
      }
      &__profile {
        width: 50px;
        height: 50px;
        border: 1px solid #022d6238;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        position: relative;

        & span {
          position: relative;
          top: 1px;
        }

        & div {
          width: 8px;
          height: 8px;
          background-color: $success-color;
          position: absolute;
          right: 3px;
          top: 3px;
          border-radius: 50%;
        }
      }

      &__name {
        font-weight: 600;
        font-size: 16px;
      }

      &__unread {
        background-color: $primary-color;
        color: #fff;
        display: inline-block;
        width: 22px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        & span {
          position: relative;
          top: 1px;
          font-size: 13px;
        }
      }
    }
  }

  &__add {
    position: absolute;
    right: 20px;
    bottom: 20px;
    box-shadow: $box-shadow;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    background-color: #3e9854;
    color: #fff;
    cursor: pointer;
  }

  &__modal {
    width: 400px;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
  }
}
