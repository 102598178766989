.imagesHome {
  box-shadow: $box-shadow;
  border-radius: 10px;
  padding: 30px;
  background-color: #fff;
  & h1 {
    font-size: 20px;
    margin-bottom: 30px;
  }
  &__main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;

    &__item {
      &__media {
        position: relative;

        & img {
          height: 500px;
          width: 100%;
          object-fit: cover;
          border-radius: 5px;
        }
        & video {
          height: 500px;
          width: 100%;
          object-fit: cover;
          border-radius: 5px;
        }

        &__edit {
          cursor: pointer;
          width: 30px;
          height: 30px;
          background-color: #fff;
          border-radius: 5px;
          position: absolute;
          top: 5px;
          right: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: $box-shadow;

          & i {
            color: $primary-color;
            font-size: 16px;
          }
        }
      }

      &__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        flex-wrap: wrap;
        margin: 5px 0;

        & p {
          font-size: 18px;
          color: $textAlt-color;
          padding-top: 5px;
        }
      }
    }
  }
}
