.prices {
  box-shadow: $box-shadow;
  border-radius: 10px;
  padding: 30px;
  background-color: #fff;
  & h1 {
    font-size: 20px;
    margin-bottom: 30px;
  }

  &__main {
    &__list {
      display: flex;
      gap: 30px;
      flex-wrap: wrap;

      &__item {
        border: 1px solid #ebebeb;
        width: 200px;
        border-radius: 10px;
        padding: 20px;
        position: relative;
        // padding-bottom: 10px;

        & h4 {
          font-size: 16px;
          margin-bottom: 20px;
        }

        & p {
          font-size: 30px;
          color: $primary-color;
        }

        & i {
          color: $primary-color;
          position: absolute;
          right: 20px;
          top: 20px;
          cursor: pointer;
        }
      }
    }
  }
}
